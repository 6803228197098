import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import mappoint from "../images/svg/mappoint.svg";
import email from "../images/svg/email.svg";
import phone from "../images/svg/phone.svg";
import Svgsprite from "../components/svgsprite";

import styles from "../css/footer.module.scss";
import formcss from "../css/form.module.scss";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.hiddenIframe = React.createRef();
    this.state = { isFormSubmitted: "no" };
    this.submitDone = this.submitDone.bind(this);
  }

  submitDone() {
    this.setState((state) => ({
      isFormSubmitted: "loading",
    }));
    this.hiddenIframe.current.onload = () => {
      this.setState((state) => ({
        isFormSubmitted: "yes",
      }));
    };
  }

  render() {
    let button = "Send message";

    if (this.state.isFormSubmitted === "no") {
      console.log("not_submited");
    }
    if (this.state.isFormSubmitted === "loading") {
      console.log("loading now");
      button = "Wait, sending...";
    }
    if (this.state.isFormSubmitted === "yes") {
      console.log("submit done!");
    }

    return (
      <Layout>
        <SEO title="Contacts" />
        <section
          data-side="contacts"
          className="contentbox wrap --fullw --wideblue"
        >
          <div className="textcontent">
            <h2>
              Boatpilot company <span>contacts</span>
            </h2>
            <ul className="list --nobullets">
              <li>
                <Svgsprite
                  svgclass={styles.icon}
                  name={mappoint.id}
                  vbox={mappoint.viewBox}
                />
                Klimentos 41-43, Klimentos Tower, Office 25, 061, Nicosia,
                Republic of Cyprus.
              </li>
              <li>
                <Svgsprite
                  svgclass={styles.icon}
                  name={email.id}
                  vbox={email.viewBox}
                />{" "}
                <a href="mailto:info@boatpilot.me">
                  Email Us info@boatpilot.me
                </a>
              </li>
              <li>
                <Svgsprite
                  svgclass={styles.icon}
                  name={phone.id}
                  vbox={phone.viewBox}
                />{" "}
                <a href="tel:+35722007092">Call us at +35722007092</a>
              </li>
            </ul>

            <h3>Drop a message</h3>

            {this.state.isFormSubmitted === "no" ||
            this.state.isFormSubmitted === "loading" ? (
              <>
                <form
                  className={formcss.form}
                  method="post"
                  target="hiddenFrame"
                  action="https://www.flexyform.com/f/f0ebfac5ce0046f0d1a6625de133fc3015b0332b"
                  //action="/"
                  onSubmit={this.submitDone}
                >
                  <div className={formcss.formrow}>
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" id="name" />
                  </div>
                  <div className={formcss.formrow}>
                    <label htmlFor="email">Email*</label>
                    <input type="email" name="email" id="email" required />
                  </div>

                  <div className={formcss.formrow}>
                    <label htmlFor="subject">Subject</label>
                    <input type="text" name="subject" id="subject" />
                  </div>

                  <div className={formcss.formrow}>
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" rows="5" />
                  </div>
                  <button
                    className={formcss.button}
                    disabled={
                      this.state.isFormSubmitted === "loading" && "disabled"
                    }
                    type="submit"
                  >
                    {button}
                  </button>
                  <input
                    className={formcss.buttonsimple}
                    type="reset"
                    value="Clear"
                  />
                </form>
                <iframe
                  ref={this.hiddenIframe}
                  title="hiddenframe"
                  name="hiddenFrame"
                  src="about:blank"
                  style={{ display: "none" }}
                ></iframe>
              </>
            ) : (
              "Thank you for your interest in our company! We usually respond within 5 business days."
            )}
          </div>
          <div className="videocontent">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.642502829786!2d33.364206916081756!3d35.16553556568667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de175ea479484b%3A0x756026012b8fe0b0!2zS2xpbWVudG9zIDQxLCBOaWNvc2lhLCDQmtC40L_RgA!5e0!3m2!1sru!2sby!4v1539093545021"
              allowFullScreen=""
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Contacts;
